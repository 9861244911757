/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

.sectionBG{
    font-family: nimbus-sans, sans-serif;
    font-weight: 900;
    font-style: normal;

    position: fixed;
    width: 250px;
    right: 25%;
    height: 100vh;
    overflow: hidden;
    z-index: -1;
}
.sectionBG .scroll{
    width: 90%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.sectionBG .scroll div{
    color : #FFF;
    font-size: 15em;
    
    white-space: nowrap;
    font-weight: 900;
    /*text-transform: uppercase;*/
    -webkit-animation : animate 400s linear;
            animation : animate 400s linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
}
.sectionBG .scroll div span{

    -webkit-text-stroke : 2px #FFF;
   -webkit-text-fill-color:transparent;
}
.sectionBG .scroll.text {
    -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
            transform: rotate(-90deg);
    z-Index : -1;
}
@-webkit-keyframes animate {
    0%{
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
    100%{
        -webkit-transform: translateX(-200%);
                transform: translateX(-200%);
    }
}
@keyframes animate {
    0%{
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    }
    100%{
        -webkit-transform: translateX(-200%);
                transform: translateX(-200%);
    }
}
.LButtons{
    width: 70px;
    height: 70px;
}


.my-node-enter {
    opacity: 0;
  }
  .my-node-enter-active {
    opacity: 1;
    -webkit-transition: opacity 2000ms;
    -o-transition: opacity 2000ms;
    transition: opacity 2000ms;
  }
  .my-node-exit {
    opacity: 1;
  }
  .my-node-exit-active {
    opacity: 0;
    -webkit-transition: opacity 2000ms;
    -o-transition: opacity 2000ms;
    transition: opacity 2000ms;
  }
  
  @media screen and (max-width: 1200px) {
    .sectionBG{    
        right: 10%;
    }
  }
  @media screen and (max-width: 600px) {
    .sectionBG{    
        width: 200px;
        right: 1px !important;
    }
  }